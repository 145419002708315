@use '../../../ui/styles/colors.scss' as colors;
@use '../../../ui/styles/breakpoints.scss' as breakpoint;
.container {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  @media (max-width: breakpoint.$mobile) {
    width: 300px;
  }
  .title {
    font-size: 24px !important;
    line-height: 1;
    align-self: center;
    font-weight: 600;
    color: colors.$white;
    text-align: center;
    margin-bottom: 0 !important;
    @media (max-width: breakpoint.$mobile) {
      font-size: 16px;
    }
  }
  span{
    text-align: center;
    color: colors.$white;
    margin-bottom: 20px;
  }
  .flex{
    font-size: 18px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media (max-width: breakpoint.$mobile) {
      flex-direction: column;
    }
    span{
      margin: 0;
    }
  }
  .button {
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    background: colors.$white;
    border-radius: 10px;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    a {
      transition: all 0.3s;
      color: colors.$secondary ;
    }
    svg {
      width: 24px;
      height: 24px;
      path {
        transition: all 0.3s;
        fill: colors.$secondary;
      }
    }
    &:hover {
      transition: all 0.3s;
      background: colors.$secondary;
    }
    &:hover svg {
      path {
        transition: all 0.3s;
        fill: colors.$white;
      }
    }
    &:hover a {
      transition: all 0.3s;
      color: colors.$white;
    }
  }
}
