@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;

.header {
  width: 100%;
  z-index: 99999999;
  position: fixed;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: min(100%, 1200px);
  }
  .sticky{
    background-color: colors.$black !important;
    transition: all 0.3s;
    border-bottom: 0 !important;
  }
  .up {
    transition: all 0.3s;
    background-color: colors.$black_50;
    box-sizing: border-box;
    width: 100%;
    padding: 41px 0 25px 0;
    border-bottom: 1px solid colors.$gray40;
    @media (max-width: breakpoint.$mobile) {
      padding: 16px 24px 16px 16px;
    }
    .column {
      display: flex;
      flex-direction: row;
      align-items: center;
      &:nth-child(2) {
        gap: 92px;
      }
      @media (max-width: breakpoint.$tablet) {
        &:nth-child(2) {
          gap: 0;
        }
      }
      .logo {
        margin: 0 30px 0 0;
        @media (max-width: breakpoint.$mobile) {
          margin: 0;
        }
      }

      .text {
        @media (max-width: breakpoint.$mobile) {
          display: none;
        }
        margin: -10px 0 0 0;
        font-size: 18px;
        line-height: 1;
        font-weight: 400;
        color: colors.$white;
      }
    }
  }
  .disappear{
    opacity: 0 !important;
    transition: all 0.3s;
    pointer-events: none;
    height: 0 !important;
  }
  .slider {
    transition: all 0.3s;
    position: relative;
    background-color: colors.$black_50;
    height: 95px;
    width: 100%;
    border-radius: 0 0 36px 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    @media (max-width: breakpoint.$mobile) {
      height: 60px;
      border-radius: 0;
    }
    .inner {
      position: absolute;
      height: 100px;
      width: 10%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 1) 75%);
      top: 0;
      z-index: 10000;

      &:last-child {
        right: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 1) 75%);
      }
    }
  }
}
