@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.banner {
  picture{
    width: 100%;
    height: 100%;
  }
  background-color: colors.$purple-dark;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 190px;
  overflow: hidden;
  @media (max-width: breakpoint.$tablet) {
    height: 113px;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
  }
  .info {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 0 20px 24px;
    justify-content: space-between;
    height: 140px;
    @media (max-width: breakpoint.$tablet) {
      padding: 12px 0 10px 16px;
      height: 90px;
    }
    .titleFlex{
      display: flex;
      flex-direction: column;
      gap: 5px;
      .date {
        font-size: 26px;
        color: colors.$white;
        font-weight: 500px;
        line-height: 1;
        margin: 0;
        @media (max-width: breakpoint.$tablet) {
          font-size: 13.7049px;
        }
      }
      .country{
        font-size: 20px;
        color: colors.$gray-ligth;
        font-weight: 500px;
        line-height: 1;
        margin: 0;
        @media (max-width: breakpoint.$tablet) {
          font-size: 11.7049px;
        }
      }
    }

    .icon {
      max-width: 192px;
      max-height: 94px;
      mix-blend-mode: lighten;
    }
    .icon2 {
      position: absolute;
      bottom: -12px;
      left: 10px;
      mix-blend-mode: lighten;
      @media (max-width: breakpoint.$tablet) {
        bottom: -8px;
      }
      @media (max-width: 600px) {
        width: 70% !important;
        height: 60% !important;
      }
    }
  }

  .imageBox {
    height: 190px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
    }
    @media (max-width: breakpoint.$tablet) {
      height: 113px;
    }
  }
  .imageBox2 {
    height: 140%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    img{
      width: 100%;
      height: 100%;
    }
    @media (max-width: breakpoint.$tablet) {
      height: 183px;
    }
  }
}
