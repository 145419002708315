@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.footer {
  width: 100%;
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: breakpoint.$mobile) {
    padding: 16px 0 32px 0;
  }
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: breakpoint.$tablet) {
      flex-direction: column;
      gap: 10px;
    }
    .socialList {
      display: flex;
      flex-direction: row;
      @media (max-width: breakpoint.$tablet) {
        border: 1px solid colors.$border;
        border-radius: 16px;
        padding: 18px 76px;
      }
      @media (max-width: breakpoint.$mobile) {
        padding: 18px 59px;
      }
      @media (max-width: breakpoint.$small_mobile) {
        padding: 18px 0;
        width: 90%;
        justify-content: center;
        gap: 40px;
      }
      .label {
        font-size: 22px;
        line-height: 0.89;
        font-weight: 500;
        color: colors.$purple-gray;
        margin: 0 20px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: breakpoint.$mobile) {
          font-size: 16px;
        }
        @media (max-width: breakpoint.$small_mobile) {
          margin: 0 5px 0 0;
        }
      }
      li {
        margin: 0 38px 0 0;
        @media (max-width: breakpoint.$mobile) {
          margin: 0 8px 0 0;
        }
        @media (max-width: breakpoint.$small_mobile) {
          margin: 0 5px 0 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    .lafabet {
      font-weight: 500;
      font-size: 17px;
      line-height: 89%;
      color: colors.$white;
      opacity: 0.4;
      padding: 18px 0;
    }
    .contacts {
      display: flex;
      flex-direction: row;
      font-size: 18px;
      line-height: 0.89;
      font-weight: 500;
      @media (max-width: breakpoint.$tablet) {
        border: 1px solid colors.$border;
        border-radius: 16px;
        padding: 18px 145px 18px 16px;
        min-width: 300px;
        justify-content: flex-start;
      }
      @media (max-width: breakpoint.$mobile) {
        font-size: 16px;
        border: 1px solid colors.$border;
        border-radius: 16px;
        padding: 18px 14px;
        min-width: 300px;
        justify-content: unset;
      }
      @media (max-width: breakpoint.$small_mobile) {
        width: 90%;
        min-width: unset;
        padding: 18px 0;
        justify-content: center;
      }
      .label {
        color: colors.$purple-gray;
        margin: 0 20px 0 0;
      }

      .email {
        color: colors.$white;
        text-decoration: none;
        margin: 0 20px 0 0;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media (max-width: breakpoint.$tablet) {
        justify-content: center;
        margin-top: 20px;
      }
      li {
        margin: 0 24px 0 0;

        &:last-child {
          margin: 0;
        }

        .link {
          font-size: 17px;
          line-height: 0.89;
          font-weight: 600;
          color: colors.$white;
          text-decoration: none;
          transition: all 0.3s;
          &:hover{
            transition: all 0.3s;
           color: colors.$secondary
          }
          @media (max-width: breakpoint.$tablet) {
            color: colors.$secondary;
          }
        }
      }
    }
  }
}
.policy {
  cursor: pointer;
  font-size: 17px;
  line-height: 0.89;
  font-weight: 600;
  color: colors.$white;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: flex-end;
  width: min(98%, 1200px);
  margin: 0 auto;
  padding: 0 16px;
  margin-top: 20px;
  &:hover{
    transition: all 0.3s;
   color: colors.$secondary
  }
  @media (max-width: breakpoint.$tablet) {
    width: min(98%, 1200px);
    &:hover{
      transition: unset;
      color: unset;
    }
    color: colors.$secondary;
    justify-content: center;
  }
  @media (max-width: breakpoint.$mobile) {
    margin-inline: unset;
    width: min(102%, 1200px) !important;
    padding: 0;
  }
}
