@use './ui/styles/colors.scss' as colors;
@import './ui/fonts/fonts.scss';
@mixin scroll {
  // fireFox
  scrollbar-color: colors.$secondary;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    background: colors.$black_50;
  }
  &::-webkit-scrollbar-thumb {
    background: colors.$secondary;
    border-radius: 25px;
    width: 100%;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: colors.$pink !important;
  }
}
* {
  margin: 0;
  padding: 0;
  font-family: 'SF', sans-serif;
}

ul,
li {
  list-style-type: none;
}
body {
  &::-webkit-scrollbar {
    width: 6px;
    background: colors.$black_50;
  }
  &::-webkit-scrollbar-thumb {
    background: colors.$secondary;
    border-radius: 25px;
    width: 100%;
  }
  background: colors.$black;
  overflow-x: hidden !important;
}
html {
  overflow-x: hidden !important;
}
.thumb-vertical {
  opacity: 0.2;
  background: colors.$secondary;
  z-index: 100;
  border-radius: 10px;
}

.app {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: colors.$black;
  button {
    cursor: pointer;
  }
  a {
    cursor: pointer;
  }
}
.swiper-pagination-progressbar-fill {
  background: colors.$purple-dark !important;
}
.swiper-pagination-progressbar {
  border-radius: 20px !important;
  top: 4px !important;
}
.swiper-button-next {
  background-color: colors.$black;
  position: absolute;
  right: 0 !important;
  top: 40px !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  transition: all 0.3s;
  &:hover{
    background-color: colors.$purple-dark;
    transition: all 0.3s;
  }
  &::after{
    margin-left: 5px;
    font-weight: bold;
    font-size: 20px !important;
    color: colors.$white;
    @media (max-width:520px) {
      font-size: 16px !important;
    }
  }
}
.swiper-button-prev {
  left: unset !important;
  background-color: colors.$black;
  position: absolute;
  right: 80px !important;
  top: 40px !important;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  transition: all 0.3s;
  &:hover{
    background-color: colors.$purple-dark;
    transition: all 0.3s;
  }
  &::after{
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px !important;
    color: colors.$white;
    @media (max-width:520px) {
      font-size: 16px !important;
    }
  }
}
