@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.content{
    padding: 182px 16px 80px 16px;
    @media (max-width: breakpoint.$tablet) {
        padding: 102px 16px 50px 16px;
    }
}
.title{
    width: 100%;
    display: flex;
    justify-content: center;
    margin:  40px 0;
}
a{
    text-decoration: none;
    color: colors.$secondary;
}