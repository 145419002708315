@use '../../ui/styles/breakpoints.scss' as breakpoint;

.container {
  width: min(98%, 1200px);
  margin: 0 auto;
  padding: 0 16px;
  @media (max-width: breakpoint.$tablet) {
    width: min(98%, 1200px);
  }
  @media (max-width: breakpoint.$mobile) {
    margin-inline: unset;
    width: min(102%, 1200px) !important;
    padding: 0;
  }
}
