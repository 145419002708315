@use '../../ui/styles/colors.scss' as colors;
@use './../../ui/styles/breakpoints.scss' as breakpoint;
.banners {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  .title {
    font-size: 3.7vw;
    font-weight: 600;
    margin: 0 0 40px 0;
    line-height: 1;
    color: colors.$white;
    z-index: 2;
    position: relative;
    @media (min-width: breakpoint.$container) {
      font-size: 50px;
    }
    @media (max-width: breakpoint.$desktop) {
      font-size: 4.5vw;
    }
    @media (max-width: breakpoint.$tablet) {
      font-size: 5.5vw;
      padding: 0 16px;
    }
    @media (max-width: breakpoint.$mobile) {
      font-size: 6vw;
    }
    @media (max-width: breakpoint.$medium_mobile) {
      font-size: 7vw;
    }
    @media (max-width: breakpoint.$inter_mobile) {
      font-size: 8vw;
    }
  }

  .box {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 52px;
    @media (max-width: breakpoint.$tablet) {
      grid-template-columns: 1fr;
      gap: 22px;
      padding: 0 16px 30px 16px;
    }
  }
}
