.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  animation: open .5s ease;
  background-color: rgb(0, 0, 0, .7);
  z-index: 1001;

  &_open {
    opacity: 1;
  }
}