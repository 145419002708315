@use '../styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.container {
  @media (max-width: breakpoint.$mobile) {
    padding: 0 0 0 16px;
  }
  .title {
    font-size: 5.6vw;
    line-height: 1.2;
    font-weight: 600;
    background: colors.$linear-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    @media (min-width: breakpoint.$container) {
      font-size: 160px;
    }
    @media (max-width: breakpoint.$desktop) {
      font-size: 6vw;
      margin: 0 0 18px 0;
    }
    @media (max-width: breakpoint.$tablet) {
      font-size: 8vw;
    }
    @media (max-width: breakpoint.$mobile) {
      font-size: 8vw;
    }
    @media (max-width: breakpoint.$medium_mobile) {
      font-size: 12vw;
      margin: 0 0 18px 0;
    }
  }

  .subtitle {
    margin-left: 5px;
    font-size: 1.3vw;
    line-height: 1.04;
    color: colors.$white;
    opacity: 0.5;
    @media (min-width: breakpoint.$container) {
      font-size: 21px;
    }
    @media (max-width: breakpoint.$desktop) {
      font-size: 1.6vw;
    }
    @media (max-width: breakpoint.$tablet) {
      font-size: 1.9vw;
    }
    @media (max-width: breakpoint.$mobile) {
      font-size: 2.2vw;
    }
    @media (max-width: breakpoint.$medium_mobile) {
      font-size: 2.5vw;
      max-width: 80%;
    }
    @media (max-width: breakpoint.$inter_mobile) {
      font-size: 3.5vw;
      max-width: 216px;
    }
  }

  &_lineHeight {
    .title {
        line-height: 0.83 !important;
    }

    .subtitle {
      margin: 12px 0 0 8px;
    }
  }
}
