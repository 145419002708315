@use '../../../ui/styles/colors.scss' as colors;
@use '../../../ui/styles/breakpoints.scss' as breakpoint;
.item {
  padding: 0 0 0 36px;
  border-left: 1px solid colors.$gray--dark;
  z-index: 1;
  position: relative;
  @media (max-width: breakpoint.$mobile) {
    border-left: unset;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .icon {
    margin: 0 0 38px 0;
    @media (max-width: breakpoint.$mobile) {
      margin: 0;
    }
    svg {
      min-width: 52px;
      min-height: 52px;
      @media (max-width: breakpoint.$mobile) {
        min-width: 39px;
        min-height: 39px;
        width: 39px;
        height: 39px;
      }
    }
  }

  .text {
    line-height: 0.98;
    font-size: 1.1vw;
    font-weight: 600;
    color: colors.$white;
    max-width: 310px;
    @media (min-width: breakpoint.$container) {
      font-size: 18px;
    }
    @media (max-width: breakpoint.$desktop) {
      font-size: 1.5vw;
    }
    @media (max-width: breakpoint.$tablet) {
      font-size: 1.6vw;
    }
    @media (max-width: breakpoint.$mobile) {
      max-width: 100% !important;
      font-size: 3vw;
      padding: 0 16px 0 0;
      line-height: unset;
    }
    @media (max-width: breakpoint.$medium_mobile) {
      font-size: 3.8vw;
      max-width: 50vw;
    }
  }
}
