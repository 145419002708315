@use "../../ui/styles/breakpoints.scss" as breakpoint;
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 40px;
  @media (max-width: breakpoint.$mobile) {
    justify-content: flex-start;
    width: 143px;
    height: 31px;
    padding-top: 6px;
  }
  @media (max-width: breakpoint.$small_mobile) {
    width: 110px;
  }
  svg {
    width: 184px;
    height: 40px;
    min-width: 184px;
    min-height: 40px;
    @media (max-width: breakpoint.$mobile) {
      width: 143px;
      min-width: 143px;
      min-height: 31px;
    }
    @media (max-width: breakpoint.$small_mobile) {
      width: 110px;
      min-width: 110px;
    }
  }
}
