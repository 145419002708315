@use '../../ui/styles/breakpoints.scss' as breakpoint;
.earn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -60px 0 0 0;
  padding: 90px 0 149px 0;
  border-radius: 64px;
  z-index: 3;
  overflow: hidden;
  position: relative;
  @media (max-width: breakpoint.$mobile) {
    background: url('./assets/images/mobileBack.jpg');
    padding: 90px 0 0 0;
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    top: -300px;
    z-index: 1;
    border-radius: 0;
  }
  .video {
    border-radius: 64px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    bottom: 0;
    left: 0;
    @media (max-width: breakpoint.$mobile) {
      display: none;
    }
    video {
      width: 100%;
      min-height: 786px;
      object-fit: cover;
    }
  }
  .title {
    margin: 0 0 150px 0;
    @media (max-width: breakpoint.$tablet) {
      margin: 0 0 150px 0;
    }
    @media (max-width: breakpoint.$mobile) {
      margin: 355px 0 60px 0;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 21px;
    max-width: 1078px;
    @media (max-width: breakpoint.$tablet) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto 40px auto;
      gap: 21px;
      max-width: 703px;
    }
    li {
      margin: 0;
      min-width: 100%;
      @media (max-width: breakpoint.$desktop) {
        width: 19%;
      }
      @media (max-width: breakpoint.$tablet) {
        min-width: unset;
        width: 22%;
      }
      @media (max-width: breakpoint.$mobile) {
        width: unset;
      }
    }
  }
}
