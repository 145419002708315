.wrapper {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  padding-top: var(--paddingTop);
  border-radius: 10px;

  &Interactive {
    &::after {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(236, 127, 0, 0.4);
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s;
    }

    &:focus-within {
      &::after {
        opacity: 0.4;
      }

      .image {
        outline: none;
      }
    }
  }
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &Interactive {
    pointer-events: none;
  }
}

.button {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}
