@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.input {
  height: 60px;
  padding: 0 14px 0 28px;
  box-sizing: border-box;
  border: 0.6px solid colors.$pink;
  border-radius: 10px;
  font-size: 21px;
  color: colors.$white;
  background: none;
  outline: none;
  @media (max-width: breakpoint.$mobile) {
    height: 54px;
    font-size: 17px;
    padding: 0 14px;
  }
  &::placeholder {
    color: colors.$white;
  }
}
