@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  svg {
    margin: 0 12px 0 0;
    @media (max-width: breakpoint.$mobile) {
      width: 21px;
      height: 16px;
    }
    @media (max-width: breakpoint.$small_mobile) {
      margin: 0 2px 0 0;
    }
  }

  .label {
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    color: colors.$white;
    @media (max-width: breakpoint.$mobile) {
      font-size: 16px;
    }
  }
}
