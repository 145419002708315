@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.mozilla {
  background: unset !important;
}
.create {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 208px 0 0 0;
  margin: -60px 0 18px 0;
  overflow: hidden;
  @media (max-width: breakpoint.$tablet) {
    padding: 150px 0 0 0;
  }
  @media (max-width: breakpoint.$mobile) {
    padding: 0;
    margin: -270px 0 18px 0;
    background: url('./assets/images/back.svg');
    background-size: 400%;
    background-repeat: no-repeat;
    background-position: right;
  }
  .background {
    svg {
      pointer-events: none;
      width: 2072px;
      height: 1104px;
    }
    position: absolute;
    top: -70%;
    left: -15%;
    width: 100%;
    display: flex;
    align-items: center;
    @media (min-width: breakpoint.$container) {
      top: -70%;
      left: -15%;
    }
    @media (max-width: breakpoint.$desktop) {
    }
    @media (max-width: breakpoint.$mobile) {
    }
    @media (max-width: breakpoint.$medium_mobile) {
      display: none;
    }
    .circle {
      position: absolute;
      width: 80.4%;
      padding: 14% 0;
      border-radius: 50%;
      background-color: colors.$secondary;
      z-index: 2;
      filter: blur(344px);
      bottom: 0;
      &_big {
        width: 100%;
        padding: 16.78% 0;
        background-color: colors.$purple;
        z-index: 1;
      }
    }
  }
  .secondBackground {
    svg {
      pointer-events: none;
      width: 772px;
      height: 504px;
    }
    position: absolute;
    bottom: -30%;
    right: -15%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: breakpoint.$container) {
      bottom: -30%;
      right: -15%;
    }
    @media (max-width: breakpoint.$desktop) {
    }
    @media (max-width: breakpoint.$mobile) {
    }
    @media (max-width: breakpoint.$medium_mobile) {
      display: none;
    }
    .circle {
      position: absolute;
      width: 80.4%;
      padding: 14% 0;
      border-radius: 50%;
      background-color: colors.$secondary;
      z-index: 2;
      filter: blur(344px);
      bottom: 0;
      &_big {
        width: 100%;
        padding: 16.78% 0;
        background-color: colors.$purple;
        z-index: 1;
      }
    }
  }
  .thirdBackground {
    svg {
      pointer-events: none;
      width: 772px;
      height: 504px;
    }
    position: absolute;
    bottom: -30%;
    left: -15%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: breakpoint.$container) {
      bottom: -30%;
      left: -15%;
    }
    @media (max-width: breakpoint.$desktop) {
    }
    @media (max-width: breakpoint.$mobile) {
    }
    @media (max-width: breakpoint.$medium_mobile) {
      display: none;
    }
    .circle {
      position: absolute;
      width: 80.4%;
      padding: 14% 0;
      border-radius: 50%;
      background-color: colors.$secondary;
      z-index: 2;
      filter: blur(344px);
      bottom: 0;
      &_big {
        width: 100%;
        padding: 16.78% 0;
        background-color: colors.$purple;
        z-index: 1;
      }
    }
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 55px 0;
    @media (max-width: breakpoint.$tablet) {
      grid-template-columns: 1fr;
    }
    .title {
      font-size: 6.2vw;
      line-height: 0.89;
      font-weight: 600;
      margin: 0;
      z-index: 3;
      position: relative;
      color: colors.$white;
      @media (min-width: breakpoint.$container) {
        font-size: 70px;
      }
      @media (max-width: breakpoint.$desktop) {
        font-size: 6.5vw;
      }
      @media (max-width: breakpoint.$tablet) {
        font-size: 7vw;
        margin: 50px 0 48px 0;
      }
      @media (max-width: breakpoint.$mobile) {
        font-size: 8vw;
        margin: 50px 0 48px 0;
        padding: 0 16px;
      }
      @media (max-width: breakpoint.$medium_mobile) {
        font-size: 9vw;
      }
      @media (max-width: breakpoint.$inter_mobile) {
        font-size: 11vw;
      }
    }

    .form {
      position: relative;
      z-index: 3;
      width: 100%;
    }
  }
}
.firefox_background {
  pointer-events: none;
  position: absolute;
  top: 50%;
  opacity: 0.5;
  left: -40%;
  width: 144.42%;
  @media (max-width: breakpoint.$tablet) {
    top: 40%;
    left: -90%;
  }
  .firefox_circle {
    position: absolute;
    width: 80.4%;
    padding: 14% 0;
    border-radius: 50%;
    background-color: colors.$secondary;
    z-index: 2;
    filter: blur(344px);
    bottom: 0;

    &_big {
      width: 100%;
      padding: 16.78% 0;
      background-color: colors.$purple;
      z-index: 1;
    }
  }
}
