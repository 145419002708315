@use '../styles/colors.scss' as colors;
@use '../styles/breakpoints.scss' as breakpoint;
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  input {
    display: none;

    &:checked + .decor {
      &::before {
        opacity: 1;
      }
      .accept{
        opacity: 1;
      }
    }
  }
  .error{
    border: 0.6px solid colors.$red !important;
    transition: all 0.3s;
  }
  .decor {
    transition: all 0.3s;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.6px solid colors.$pink;
    margin: 0 16px 0 0;
    @media (max-width: breakpoint.$mobile) {
      width: 25px;
      height: 25px;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: colors.$secondary;
      transition: opacity 0.3s ease-in;
      opacity: 0;
      border-radius: 10px;
    }
    .accept{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      opacity: 0;
      position: absolute;
    }
  }

  .label {
    font-size: 17px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.01em;
    color: colors.$white;
    @media (max-width: breakpoint.$mobile) {
      font-size: 15.2512px;
    }
    a {
      text-decoration: underline;
      color: colors.$white;
    }
  }
}
