@use "../../ui/styles/colors.scss" as colors;
@use "../../ui/styles/breakpoints.scss" as breakpoint;
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  cursor: grab;
  @media (max-width: breakpoint.$mobile) {
    font-size: 12.2px;
  }
  .text {
    font-weight: 600;
    color: colors.$white;
  }

  .price {
    font-weight: 600;
    margin-left: 5px;
    color: colors.$secondary;
    span{
      color: colors.$white;
    }
  }
}
