@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.about {
  width: 100%;
  margin: -60px 0 0 0;
  z-index: 2;
  position: relative;
  background-color: colors.$black;
  padding: 0 0 240px 0;
  &::before {
    margin-top: -50px;
    border-radius: 64px 64px 0 0;
    background: url('./assets/images/back.jpg') no-repeat center center;
    background-size: cover;
    content: ' ';
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: -1;
  }
  @media (max-width: breakpoint.$desktop) {
    padding: 0 0 150px 0;
  }
  @media (max-width: breakpoint.$mobile) {
    border-radius: 0;
    overflow: hidden;
    margin: -120px 0 0 0;
    padding: 0;
    &::before {
      background: url('./assets/images/mobileBack.jpg');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin: 148px auto 20px auto;
      font-size: 4.6vw;
      max-width: 80%;
      line-height: 0.92;
      font-weight: 600;
      color: colors.$white;
      text-align: center;
      @media (min-width: breakpoint.$container) {
        font-size: 60px;
      }
      @media (max-width: breakpoint.$desktop) {
        font-size: 5.6vw;
        line-height: 93%;
      }
      @media (max-width: breakpoint.$tablet) {
        font-size: 5.1vw;
        margin: 150px auto 18px auto;
      }
      @media (max-width: breakpoint.$mobile) {
        margin: 214px auto 18px auto;
      }
      @media (max-width: breakpoint.$medium_mobile) {
        max-width: 80%;
        font-size: 6vw;
      }
    }
    .background {
      svg {
        pointer-events: none;
        width: 1672px;
        height: 804px;
      }
      position: absolute;
      top: 85%;
      left: -35%;
      width: 100%;
      display: flex;
      align-items: center;
      @media (min-width: breakpoint.$container) {
        top: 85%;
        left: -35%;
      }
      @media (max-width: breakpoint.$desktop) {
        top: 80%;
        left: -35%;
      }
      @media (max-width: breakpoint.$mobile) {
        top: 75%;
        left: -35%;
      }
      @media (max-width: breakpoint.$medium_mobile) {
        display: none;
      }
      .circle {
        position: absolute;
        width: 80.4%;
        padding: 14% 0;
        border-radius: 50%;
        background-color: colors.$secondary;
        z-index: 2;
        filter: blur(344px);
        bottom: 0;
        &_big {
          width: 100%;
          padding: 16.78% 0;
          background-color: colors.$purple;
          z-index: 1;
        }
      }
    }
    .box {
      transform: skew(30deg, 0);
      background-color: colors.$secondary;
      padding: 5px 28px;
      border-radius: 3px;
      margin: 0 0 95px 0;
      @media (max-width: breakpoint.$mobile) {
        padding: 4px 18px;
        margin: 0 0 70px 0;
      }
      p {
        transform: skew(-30deg, 0);
        font-size: 23px;
        color: colors.$white;
        font-weight: 600;
        @media (max-width: breakpoint.$desktop) {
          font-size: 18.2778px;
          line-height: 93%;
        }
        @media (max-width: breakpoint.$mobile) {
          font-size: 11.7374px;
          line-height: 89%;
        }
      }
    }

    .name {
      align-self: flex-start;
      margin: 0 0 80px 0;
      @media (max-width: breakpoint.$mobile) {
        margin: 0 0 40px 0;
      }
    }

    .list {
      max-width: 1078px;
      align-self: flex-start;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 21px;
      @media (max-width: breakpoint.$tablet) {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 21px;
        align-self: center;
        max-width: 703px;
      }
      li {
        min-width: 100%;
        margin: 0;
        @media (max-width: breakpoint.$desktop) {
          margin: 0;
          width: 19%;
        }
        @media (max-width: breakpoint.$tablet) {
          margin: 0;
          width: 22%;
          min-width: unset;
        }
        @media (max-width: breakpoint.$mobile) {
          margin: 0;
          width: unset;
        }
        &:last-child {
          margin: 0;
        }
      }

      &_margin {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 21px;
        margin: 10px 0 150px 0;
        @media (max-width: breakpoint.$tablet) {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          margin: 0 auto 150px auto;
          gap: 21px;
        }
        @media (max-width: breakpoint.$mobile) {
          margin: 0 auto 80px auto;
        }
      }
      &_secondMargin {
        margin: 10px 0 13px 0;
        @media (max-width: breakpoint.$mobile) {
          margin: 40px auto 80px auto;
        }
      }
    }
  }
}
