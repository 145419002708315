@font-face {
  src: url('./SF-UI-Display-Light.woff2'),
  url("./SF-UI-Display-Light.ttf") format("truetype");
  font-family: 'SF';
  font-weight: 300;
}

@font-face {
  src: url('./SF-UI-Display-Regular.woff2'),
  url("./SF-UI-Display-Regular.ttf") format("truetype");
  font-family: 'SF';
  font-weight: 400;
}

@font-face {
  src: url('./SF-UI-Display-Medium.woff2'),
  url("./SF-UI-Display-Medium.ttf") format("truetype");
  font-family: 'SF';
  font-weight: 500;
}

@font-face  {
  src: url('./SF-UI-Display-Semibold.woff2'),
  url("./SF-UI-Display-Semibold.ttf") format("truetype");
  font-family: 'SF';
  font-weight: 600;
}

@font-face {
  src: url('./SF-UI-Display-Bold.woff2'),
  url("./SF-UI-Display-Bold.ttf") format("truetype");
  font-family: 'SF';
  font-weight: 700;
}