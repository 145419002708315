.video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    @media only screen and (orientation: landscape) and (max-device-width: 667px) and (min-device-width: 320px) {
        display: flex !important;
      }
      video{
        width: 100%;
        height: 100vh;
      }
      img{
        transform: rotate(135deg);
        width: 30%;
        height: 60vh;
      }
}