@use '../../ui/styles/colors.scss' as colors;
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: colors.$color-violet;

  z-index: 99999;

  svg {
    margin-bottom: 120px;
    min-width: 200px;
  }

  &-wrapper {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);

    &--hidden {
      visibility: hidden;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.2);
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      left: calc(50% - 2px);
      top: calc(50% - 2px);
      width: 4px;
      height: 4px;
      background: white;
      border-radius: 50%;
      transform: rotate(45deg) translateX(18px);
      animation: loading 2s linear infinite;
    }

    &--button {
      margin: 0 auto;
      width: 14px;
      height: 14px;
      border-color: rgba(255, 255, 255, 0.7);

      &:before {
        width: 200%;
        height: 200%;
        border-color: rgba(255, 255, 255, 0.7);
      }

      &:after {
        left: calc(50% - 1px);
        top: calc(50% - 1px);
        width: 3px;
        height: 3px;
        transform: rotate(45deg) translateX(11px);
        animation: buttonLoading 2s linear infinite;
      }
    }

    @keyframes buttonLoading {
      from {
        transform: rotate(0deg) translateX(11px);
      }
      to {
        transform: rotate(360deg) translateX(11px);
      }
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0deg) translateX(18px);
    }
    to {
      transform: rotate(360deg) translateX(18px);
    }
  }
}
