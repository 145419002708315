@use '../../ui/styles/breakpoints.scss' as breakpoint;
.wrapper {
  z-index: 2;
  width: 100%;
  max-height: 236px;
  position: relative;
  max-width: 1200px;
  padding: 80px 0 10px 0;
  margin-top: -105px;
}
