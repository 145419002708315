@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.button {
  background: none;
  border: none;
  background-color: colors.$secondary;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: breakpoint.$mobile) {
    background-color: inherit;
    border: 2px solid colors.$secondary;
  }
  .label {
    color: colors.$white;
    font-weight: 600;
    line-height: 1;
  }

  &_noArrow {
    justify-content: center;
  }

  &_size {
    &_sm {
      padding: 13px 28px;
      border-radius: 9px;
      @media (max-width: breakpoint.$mobile) {
        padding: 11px 19px;
      }
      .label {
        font-size: 18px;
        margin: 0 12px 0 0;
        @media (max-width: breakpoint.$mobile) {
          font-size: 14px;
        }
      }

      .arrow {
        margin: 2px 0 0 0;
        @media (max-width: breakpoint.$mobile) {
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        svg {
          width: 6px;
          height: 10.5px;
          @media (max-width: breakpoint.$mobile) {
            width: 3.5px;
            height: 6px;
          }
        }
      }
    }

    &_lg {
      padding: 16px 60px;
      border-radius: 12px;

      .label {
        font-size: 26px;
        margin: 0 24px 0 0;
      }

      .arrow {
        margin: 6px 0 0 0;

        svg {
          width: 8px;
          height: 13.87px;
        }
      }
    }

    &_xl {
      padding: 12px 0;
      height: 50px;
      border-radius: 10px;

      .label {
        font-size: 30px;
        @media (max-width: breakpoint.$mobile) {
          font-size: 18px;
        }
      }
    }
  }
}
