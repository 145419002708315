@use '../styles/colors.scss' as colors;
@use '../styles/breakpoints.scss' as breakpoint;
.modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  visibility: hidden;
  overflow: hidden;
  scroll-behavior: smooth;
  .container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    z-index: 1001;
    background: colors.$linear-gradient-dark;
    padding: 30px;
    border-radius: 10px;
    @media (max-width: breakpoint.$mobile) {
      padding: 32px 16px;
    }
    .close {
      width: 16px;
      height: 16px;
      border: none;
      background: none;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      position: absolute;
      top: 16px;
      right: 16px;
      @media (max-width: breakpoint.$mobile) {
        top: 16px;
        right: 16px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &_open {
    visibility: visible;
    pointer-events: all;

    .container {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}
