@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.experience {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: top;
  margin: -236px 0 0 0;
  padding: 236px 0 60px 0;
  position: relative;
  overflow: hidden;
  @media (max-width: breakpoint.$mobile) {
    background: unset;
    padding: 171px 0 60px 0;
  }
  .video{
    position: absolute;
    width: 100%;
    min-height: 80%;
    z-index: 0;
    overflow: hidden;
    video{
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .link {
    cursor: pointer;
    position: absolute;
    right: -64px;
    bottom: 40%;
    border-radius: 12px;
    background-color: colors.$secondary;
    padding: 22px 112px 27px 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    z-index: 5;
    border: 0;
    transform: skew(25deg, 0);
    @media (max-width: breakpoint.$desktop) {
      padding: 18px 100px 18px 49px;
    }
    @media (max-width: breakpoint.$tablet) {
      padding: 14px 100px 14px 49px;
    }
    @media (max-width: breakpoint.$mobile) {
      padding: 18px 70px 18px 49px;
      right: -40px;
      bottom: 0;
      border-radius: 5px;
      z-index: 3;
    }
    .label {
      transform: skew(-25deg, 0);
      top: 0;
      font-size: 33px;
      margin: 0 48px 0 0;
      font-weight: 700;
      color: colors.$white;
      @media (min-width: breakpoint.$container) {
        font-size: 22px;
      }
      @media (max-width: breakpoint.$desktop) {
        font-size: 23px;
        line-height: 98%;
      }
      @media (max-width: breakpoint.$tablet) {
        margin: 0 20px 0 0;
      }
    }

    svg {
      margin: 8px 0 0 0;
      transform: skew(-25deg, 0);
      @media (max-width: breakpoint.$mobile) {
        width: 7.5px;
        height: 13.2px;
      }
      svg {
        width: 11px;
        height: 19px;
      }
    }
  }
  .titleContainer {
    
    z-index: 2;
    @media (max-width: breakpoint.$mobile) {
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      background: black;
    }
    .mobileBackground{
      pointer-events: none;
      width: 100%;
      height: 100%;
      @media (min-width: breakpoint.$mobile) {
        display: none;
      }
      img{
        min-height: 455px;
        height: 100%;
        width: 100%;
      }
    }
    .title {
      font-size: 4.6vw;
      line-height: 100%;
      margin: 140px 0 90px 0;
      color: colors.$white;
      font-weight: 600px;
      max-width: 750px;
      z-index: 1;
      max-width: 40vw;
      min-height: 200px;
      @media (min-width: breakpoint.$container) {
        font-size: 60px;
        max-width: 470px;
      }
      @media (max-width: breakpoint.$desktop) {
        font-size: 4.7vw;
        max-width: 450px;
        margin: 140px 0 50px 0;
      }
      @media (max-width: breakpoint.$tablet) {
        font-size: 5vw;
        max-width: 50vw;
        min-height: unset;
      }
      @media (max-width: breakpoint.$mobile) {
        position: absolute;
        z-index: 3;
        bottom: 10px;
        font-size: 5vw;
        max-width: 100%;
        margin: 0;
        padding: 0 16px 10px 16px;
        width: 90%;
      }
      @media (max-width: breakpoint.$inter_mobile) {
        font-size: 6.5vw;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    z-index: 1;
    @media (max-width: breakpoint.$mobile) {
      background: black;
    }
    .arrow {
      background: none;
      border: none;
      display: flex;
      padding: 0;
      width: 51px;
      height: 52px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 76px 0 0;
      &:hover {
        svg {
          transform: scale(1.2);
          transition: all 0.3s;
        }
      }
      @media (max-width: breakpoint.$mobile) {
        display: none;
      }
      svg {
        transition: all 0.3s;
        min-width: 51px;
        min-height: 52px;
      }
    }

    .list {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      @media (max-width: breakpoint.$mobile) {
        flex-direction: column;
        min-height: 190px;
        gap: 30px;
        margin: 40px 0 60px 0;
      }
      li {
        margin: 0 42px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .background {
    pointer-events: none;
    min-width: 3064px;
    min-height: 2532px;
    right: -120%;
    bottom: -220%;
    width: 100%;
    padding: 35% 0;
    position: absolute;
    box-sizing: border-box;
    @media (min-width: breakpoint.$container) {
      right: -100%;
      bottom: -220%;
    }
    @media (max-width: breakpoint.$desktop) {
      right: -220%;
      bottom: -200%;
    }
    @media (max-width: breakpoint.$mobile) {
      right: 0;
      bottom: -55%;
    }
    @media (max-width: breakpoint.$medium_mobile) {
      display: none;
    }
  }
}
