$gray: #8c8c8c;
$gray-ligth: #adadad;
$gray40: #8c8c8c66;
$gray-dark: #544f58;
$gray--dark: #373737;
$white: #ffffff;
$secondary: #e00c69;
$purple-gray: #615760;
$purple: #830ce0;
$purple-dark: #33053b;
$black: #000000;
$linear-gradient: linear-gradient(110.61deg, #e00c69 13.52%, #ce03ae 82.1%);
$linear-gradient-dark: linear-gradient(139.37deg, #301243 3.26%, #290a5c 100.02%);
$divider: #6f547c;
$pink: rgb(189, 106, 182, 0.5);
$border: #2c2c2c;
$black--light: #000000d3;
$black_50: rgba(0, 0, 0, 0.486);
$red: red;
$color-white--light: #adadad;
$color-dark-light: #6f547c;
$color-violet: #190333;
