@use '../../ui/styles/colors.scss' as colors;

.area {
  min-height: 70px;
  max-height: 70px;
  width: 100%;
  padding: 22px 16px 16px 28px;
  font-size: 21px;
  line-height: 1.04;
  color: colors.$white;
  border-radius: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  &::placeholder {
    color: colors.$white;
  }
}
.focusContainer{
  border: 0.6px solid colors.$secondary !important;
  transition: all 0.1s;
}
.container {
  transition: all 0.1s;
  min-height: 110px;
  position: relative;
  border: 0.6px solid colors.$pink;
  border-radius: 10px;
  margin: 0 0 26px 0;
  .attach {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: colors.$secondary;
    width: 80px;
    opacity: 0;
  }
  .inputArea {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: colors.$secondary;
    &:hover svg {
      transform: scale(1.05);
    }
  }
}
