@use '../../../ui/styles/colors.scss' as colors;

.paragraph{
    color:colors.$white;
    margin: 10px 0;
}
.title{
    font-weight: bold;
    color: colors.$secondary;
    margin-right: 5px;
}