@use '../../ui/styles/breakpoints.scss' as breakpoint;

.container {
  width: min(96%, 1200px);
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 16px;
  min-height: 744px;
  height: calc(100vh + 50px);
  @media (max-width: breakpoint.$mobile) {
    height: unset;
    margin-inline: unset;
    width: 100%;
    padding: 0;
    min-height: unset;
  }
}
