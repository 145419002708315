@use './../../ui/styles/breakpoints.scss' as breakpoint;
@use './../../ui/styles/colors.scss' as colors;
.form {
  @media (max-width: breakpoint.$mobile) {
    padding: 0 16px;
  }
  .fieldset {
    border: none;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 26px;
    margin: 0 0 20px 0;
    @media (max-width: breakpoint.$mobile) {
      grid-template-columns: 1fr;
      gap: 21px;
    }
    .input {
      transition: all 0.3s;
      &:focus{
        transition: all 0.3s;
        border:1px solid colors.$secondary !important;
      }
      position: relative;
      z-index: 3;
    }
  }
  .password{
    position: relative;
    input{
      width: 100%;
    }
  }
  .show{
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: calc(50% - 24px/2);
    z-index: 4;
    svg{
      width: 24px;
      height: 24px;
    }
  }
  .textarea {
    margin: 0 0 26px 0;
    position: relative;
    z-index: 3;
    @media (max-width: breakpoint.$mobile) {
      padding: 18px 16px;
      font-size: 17px;
      margin: 0 0 10px 0;
    }
  }

  .checkbox {
    margin: 0 0 24px 0;
    position: relative;
    z-index: 3;
    @media (max-width: breakpoint.$mobile) {
      margin: 0 0 20px 0;
    }
  }

  .button {
    position: relative;
    z-index: 3;
    padding: 19px 64px;
    @media (max-width: breakpoint.$mobile) {
      padding: 14px 70px;
      background: colors.$secondary;
    }
  }
}
.rule {
  margin-right: 5px;
}
.selectError{
  border: 0.6px solid colors.$red !important;
  transition: all 0.3s;
}
.languageDropdown {
  transition: all 0.3s;
  min-width: 29px;
  z-index: 110;
  border: 0.6px solid colors.$pink;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer !important;
}

.passError{
  color: colors.$secondary;
  padding-bottom: 10px;
}