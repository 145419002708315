@use '../../ui/styles/colors.scss' as colors;
@use '../../ui/styles/breakpoints.scss' as breakpoint;
.card {
  padding: 24px;
  border-radius: 30px;
  border: 0.7px solid colors.$divider;
  width: 100%;
  height: 277px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
  @media (max-width: breakpoint.$desktop) {
    height: 240px;
    padding: 24px 16px;
  }
  @media (max-width: breakpoint.$tablet) {
    width: 160px;
    height: 193px;
    padding: 24px 16px;
  }
  @media (max-width: breakpoint.$mobile) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }
  @media (max-width: breakpoint.$small_mobile) {
    width: 140px;
  }
  &_reverse {
    flex-direction: column-reverse;
  }

  .icon {
    svg {
      min-width: 36px;
      min-height: 36px;
      @media (max-width: breakpoint.$mobile) {
        min-width: 31px;
        min-height: 31px;
        width: 31px;
        height: 31px;
      }
    }
  }

  .text {
    font-size: 1.3vw;
    max-width: 225px;
    line-height: 1.04;
    font-weight: 600;
    color: colors.$white;
    @media (min-width: breakpoint.$container) {
      font-size: 18px;
    }
    @media (max-width: breakpoint.$desktop) {
      font-size: 1.3vw;
    }
    @media (max-width: breakpoint.$tablet) {
      font-size: 1.5vw;
    }
    @media (max-width: breakpoint.$mobile) {
      font-size: 2vw;
    }
    @media (max-width: breakpoint.$medium_mobile) {
      font-size: 2.5vw;
    }
    @media (max-width: breakpoint.$inter_mobile) {
      font-size: 3.5vw;
    }
  }
}
