@use './../../ui/styles/colors.scss' as colors;
@use './../../ui/styles/breakpoints.scss' as breakpoint;
.language-dropdown {
  min-width: 65px;
  z-index: 5;
  height: 100%;
  cursor: pointer !important;
  &__multi-value__remove {
    transition: all 0.3s;
    &:hover {
      background-color: colors.$secondary !important;
      color: white !important;
      transition: all 0.1s;
    }
  }
  &__single-value {
    color: colors.$white !important;
  }

  &__value-container {
    padding: 0 !important;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 24px; /* ширина для вертикального скролла */
      height: 8px; /* высота для горизонтального скролла */
      background-color: colors.$pink;
      @media (max-width: breakpoint.$tablet) {
        width: 12px; /* ширина для вертикального скролла */
      height: 4px; /* высота для горизонтального скролла */
      }
  }
  &::-webkit-scrollbar-thumb {
    background-color: #843465;
    border-radius: 9em;
    box-shadow: inset 1px 1px 10px colors.$secondary;
}
  }

  &__indicator {
    padding: 0 8px 0 0 !important;

    svg {
      width: 20px;
      height: 15px;
      padding: 0 !important;
    }
  }

  &__control {
    cursor: pointer !important;
    font-size: 21px;
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 3px 0 3px 28px;
    box-shadow: none !important;
    min-height: auto !important;
    min-width: 100% !important;
    height: 53px;
    &--is-focused,
    &:hover {
      box-shadow: none;
    }
    @media (max-width: breakpoint.$desktop) {
      font-size: 17px;
      padding: 3px 0 3px 14px;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__menu {
    background-color: colors.$white !important;
  }

  &__menu-list {
    background-color: colors.$black--light;
    padding: 0 !important;
    border-radius: 4px;
    font-size: 21px;
    @media (max-width: breakpoint.$desktop) {
      font-size: 17px;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: colors.$white;
      border-radius: 2px;
    }
  }

  &__option {
    cursor: pointer !important;
    padding: 7px 8px;
    color: colors.$white !important;
    background-color: colors.$black !important;
    &--is-focused,
    &--is-selected,
    &:hover {
      color: colors.$black--light !important;
      background-color: colors.$white !important;
    }
  }
}
