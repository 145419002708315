@use '../../../ui/styles/colors.scss' as colors;
@use '../../../ui/styles/breakpoints.scss' as breakpoint;
.container {
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  @media (max-width: breakpoint.$mobile) {
    width: 300px;
  }
  .title {
    font-size: 60px;
    line-height: 1;
    margin: 0 0 24px 0;
    font-weight: 600;
    color: colors.$white;
    align-self: flex-start;
    @media (max-width: breakpoint.$mobile) {
      font-size: 42px;
    }
  }

  .form {
    width: 100%;
    padding: 0;

    .input {
      width: 100%;
      margin: 0 0 16px 0;
      height: 53px;
    }

    .forgot {
      background: none;
      border: none;
      cursor: pointer;
      color: colors.$secondary;
      font-size: 18px;
      line-height: 1.04;
      margin: 0 0 16px 12px;
      @media (max-width: breakpoint.$mobile) {
        font-size: 14px;
      }
    }

    .button {
      width: 100%;
    }
  }
}
